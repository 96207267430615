const collectionData = {
  'Clarice The Brand': {
    title: 'La Beauté Collection',
    photos: [
      '/img/Editorial Clarice/DSC_1415.JPG',
      '/img/Editorial Clarice/40-SAM_0458.jpg',
      '/img/Editorial Clarice/DSC_1361.JPG',
      '/img/Editorial Clarice/214-SAM_0141.jpg',
      '/img/Editorial Clarice/211-SAM_0147.jpg',
      '/img/Editorial Clarice/200-SAM_0161.jpg',
      '/img/Editorial Clarice/189-SAM_0189.jpg',
      '/img/Editorial Clarice/170-SAM_0221.jpg',
      '/img/Editorial Clarice/121-SAM_0302.jpg',
      '/img/Editorial Clarice/DSC_1431.JPG',
      '/img/Editorial Clarice/134-SAM_0277.jpg',
      '/img/Editorial Clarice/61-SAM_0405.jpg',
      '/img/Editorial Clarice/145-SAM_0259.jpg',
      '/img/Editorial Clarice/97-SAM_0346.jpg',
      '/img/Editorial Clarice/50-SAM_0426.jpg',
      '/img/Editorial Clarice/68-SAM_0390.jpg',
      '/img/Editorial Clarice/50-SAM_0426.jpg',
    ],
    intro: 'Introducing the newest “La Beauté Collection” by Clarice The Brand',
    description: 'A collection destined to evoke confidence and elegance in every stride. Join us on this enchanting journey as we embrace your uniqueness with our meticulously curated hand crafted designs. Cause you were born to stand out.',
    collection: 'labeaute',
  },
  'The Glitz & Glamour Collection': {
    title: 'The Glitz & Glamour Collection',
    photos: [
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup cheetah y vino.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/cheetah closeup.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/cheetah closeupp.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup brillos y vino.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea sentada sideways.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/yo boss babe close up.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea y sofia sentadas.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/vino drink closeup.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup los dos negros.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/full body brillos hot cabello.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/maria closeup rojos.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/negros mid body cool.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/yo fullbody fav creo.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/regina caminando volteando.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/Regina sentada lado cool.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/regina drink hot.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/Closeup morados negros.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/negros mid body fav.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/fullbody maria.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea sentada.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup negros brillos.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/fullbody yo hot brillos.webp',
    ],
    intro: 'Introducing the newest “The Glitz & Glamour Collection” by Clarice The Brand',
    description: 'A collection destined to evoke confidence and elegance in every stride. Join us on this enchanting journey as we embrace your uniqueness with our meticulously curated hand crafted designs. Cause you were born to stand out.',
    collection: 'theglitzglamour',
  },
};

export default collectionData;