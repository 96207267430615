const editorialData = {
  'La Beauté Collection': {
    title: 'La Beauté Collection',
    photos: [
      '/img/EditorialClariceWebp/DSC_1426.webp',
      '/img/EditorialClariceWebp/40-SAM_0458.webp',
      '/img/EditorialClariceWebp/24-SAM_0477.webp',
      '/img/EditorialClariceWebp/SAM_0410_44397016.webp',
      '/img/EditorialClariceWebp/72-SAM_0385.webp',
      '/img/EditorialClariceWebp/64-SAM_0397.webp',
      '/img/EditorialClariceWebp/87-SAM_0361.webp',
      '/img/EditorialClariceWebp/84-SAM_0365.webp',
      '/img/EditorialClariceWebp/169-SAM_0224.webp',
      '/img/EditorialClariceWebp/170-SAM_0221.webp',
      '/img/EditorialClariceWebp/212-SAM_0144.webp',
      '/img/EditorialClariceWebp/DSC_1431.webp',
      '/img/EditorialClariceWebp/221-SAM_0124.webp',
      '/img/EditorialClariceWebp/202-SAM_0159.webp',
      '/img/EditorialClariceWebp/DSC_1426.webp',
      '/img/EditorialClariceWebp/134-SAM_0277.webp',
      '/img/EditorialClariceWebp/153-SAM_0250.webp',
      '/img/EditorialClariceWebp/20-SAM_0487.webp',
      '/img/EditorialClariceWebp/188-SAM_0190.webp',
      '/img/EditorialClariceWebp/DSC_1415.webp',
      '/img/EditorialClariceWebp/114-SAM_0311.webp',
      '/img/EditorialClariceWebp/226-SAM_0115.webp',
    ],
  },
  'The Glitz & Glamour Collection': {
    title: 'The Glitz & Glamour Collection',
    photos: [
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup cheetah y vino.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/cheetah closeup.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/cheetah closeupp.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup brillos y vino.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea sentada sideways.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/yo boss babe close up.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea y sofia sentadas.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/vino drink closeup.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup los dos negros.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/full body brillos hot cabello.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/maria closeup rojos.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/negros mid body cool.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/yo fullbody fav creo.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/regina caminando volteando.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/Regina sentada lado cool.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/regina drink hot.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/Closeup morados negros.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/negros mid body fav.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/fullbody maria.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/andrea sentada.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/closeup negros brillos.webp',
      '/img/ClariceGlitz&GlamourCollectionWEBP/fullbody yo hot brillos.webp',
    ],
  },
};

export default editorialData;
